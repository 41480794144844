import React from "react";
import DescriptionIcon from "@material-ui/icons/Description";
import { useStyles } from "../styles";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";

export const EmptyStateDoc = () => {
  const isDesktop = useDesktopMediaQuery();
  const style = useStyles({ isDesktop })();

  return (
    <div className={style.uploadDocContainer}>
      <div>
        <DescriptionIcon />
        <span className={style.docText}>No document added yet</span>
      </div>
    </div>
  );
};
