import React from "react";

import { Shimmer } from "@my-scoot/component-library-legacy";
import { makeStyles } from "@material-ui/core";

import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
        height: "179px",
        border: "1.5px solid #E7E6E4",
        borderRadius: "8px",
        margin: "20px 16px 0",
    },
    avatar: {
        margin: "12px",
    },
    top: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    leftContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "14px",
        marginBottom: "11px",
        marginTop: "20px",
    },
    rightContainer: {
        margin: "16px",
    },
    content: {
        width: "100%",
    },
    option: {
        display: "flex",
        gap: "12px",
        marginTop: "14px",
    },
    optionItem: {
        display: "flex",
        flexDirection: "column",
        gap: "8px",
    },
    dark: {
        background: "#F3F2F1",
    },
    mobileRoot: {
        border: "1.5px solid #E7E6E4",
        borderRadius: "8px",
        margin: "20px 16px 0",
    },
    mobileHeader: {
        margin: "12px 0px 0px 12px",
        display: "flex",
        alignItems: "center",
        gap: "12px",
    },
    mobileContent: {
        display: "flex",
        flexDirection: "column",
        margin: "12px",
        gap: "14px",
    },
    divider: {
        paddingLeft: "12px",
        marginTop: "6px",
    },
    mobileBottom: {
        display: "flex",
        gap: "36px",
        padding: "16px 12px",
        background: "#EEECF9",
        borderRadius: "0px 0px 5px 5px",
        margin: "-1.5px",
    },
}));

const PastEmailsLoadingShimmerCard = ({ isDesktop }) => {
    const classes = useStyles();

    const OptionItem = () => (
        <div className={classes.optionItem}>
            <Shimmer borderRadius="8px" height="17px" width="34px" />
            <Shimmer borderRadius="8px" height="14px" width="60px" />
        </div>
    );

    const DesktopShimmer = ({ className }) => (
        <div className={classnames(classes.root, className)}>
            <Shimmer
                height="32px"
                width="32px"
                borderRadius="50%"
                wrapper_classname={classes.avatar}
            />
            <div className={classes.content}>
                <div className={classes.top}>
                    <div className={classes.leftContainer}>
                        <Shimmer
                            borderRadius="8px"
                            height="17px"
                            width="151px"
                        />
                        <Shimmer
                            borderRadius="8px"
                            height="14px"
                            width="372px"
                        />
                        <Shimmer
                            borderRadius="8px"
                            height="14px"
                            width="197px"
                        />
                    </div>
                    <Shimmer
                        wrapper_classname={classes.rightContainer}
                        borderRadius="8px"
                        height="17px"
                        width="107px"
                    />
                </div>
                <Shimmer height="2px" width="100%" />
                <div className={classes.option}>
                    <OptionItem />
                    <Shimmer width="1.5px" />
                    <OptionItem />
                    <Shimmer width="1.5px" />
                    <OptionItem />
                </div>
            </div>
        </div>
    );

    const MobileShimmer = ({ className }) => (
        <div className={classnames(classes.mobileRoot, className)}>
            <div className={classes.mobileHeader}>
                <Shimmer borderRadius="50%" height="32px" width="32px" />
                <Shimmer borderRadius="8px" height="17px" width="151px" />
            </div>

            <Shimmer
                wrapper_classname={classes.divider}
                height="1.5px"
                width="100%"
            />

            <div className={classes.mobileContent}>
                <Shimmer borderRadius="8px" height="14px" width="100%" />
                <Shimmer borderRadius="8px" height="14px" width="50%" />
            </div>

            <div className={classes.mobileBottom}>
                <OptionItem />
                <OptionItem />
                <OptionItem />
            </div>
        </div>
    );

    return isDesktop ? (
        <>
            <DesktopShimmer />
            <DesktopShimmer className={classes.dark} />
            <DesktopShimmer />
        </>
    ) : (
        <>
            <MobileShimmer />
            <MobileShimmer className={classes.dark} />
            <MobileShimmer />
        </>
    );
};

export default PastEmailsLoadingShimmerCard;
