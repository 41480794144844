import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  () => ({
    desktopModalPaper: {
      // maxWidth: "564px",
      width: "100%",
      padding: "0px !important",
    },
    blackBold: {
      fontWeight: "700",
      fontSize: ({ isDesktop }) => (!isDesktop ? "26px" : "38px"),
      lineHeight: "46px",
      /* or 121% */

      textAlign: "center",

      color: "rgba(0, 0, 0, 0.85)",
    },
    boldPurple: {
      fontWeight: "700",
      fontSize: ({ isDesktop }) => (!isDesktop ? "26px" : "38px"),
      lineHeight: "46px",
      /* or 121% */

      textAlign: "center",
      color: "#493AB1",
    },
    para: {
      fontWeight: "400",
      fontSize: ({ isDesktop }) => (!isDesktop ? "14px" : "18px"),
      lineHeight: "24px",
      /* or 133% */

      textAlign: "center",
      letterSpacing: "0.02em",

      color: "#6D675F",
      paddingTop: ({ isDesktop }) => (!isDesktop ? "0px" : "20px"),
      paddingBottom: ({ isDesktop }) => (!isDesktop ? "0px" : "27px"),
    },
    buttonDiv: {
      width: "100%",
      paddingBottom: "53px",
      padding: ({ isDesktop }) => (!isDesktop ? "15px" : "18px 32px"),
      position: ({ isDesktop }) => !isDesktop && "fixed",
      bottom: ({ isDesktop }) => !isDesktop && "0",
    },
    contentWrapper: {
      padding: ({ isDesktop }) => (!isDesktop ? "12px" : "18px 32px"),
    },
    contentWrapperAlt: {
      padding: ({ isDesktop }) => (!isDesktop ? "12px" : "18px 32px"),
      marginTop: "50px",
    },
    introTile: {
      display: "flex",
      gap: "15px",
    },
    introTitle: {
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "28px",
      /* or 121% */
      color: "#272522",
    },
    introPara: {
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "18px",
      /* or 121% */
      color: "rgba(39, 37, 34, 0.5)",
    },
    introTileWrapper: {
      marginTop: "40px",
      marginBottom: "40px",
      display: "flex",
      flexDirection: "column",
      gap: "24px",
    },
    mobile_modal_paper: {
      height: "92vh",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
