import React, { useEffect } from "react";
import { Button } from "@my-scoot/component-library-legacy";
import { useHistory } from "react-router-dom";
import { useListController } from "react-admin";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { dataProvider } from "data";
import Loading from "./Loading";

import { useStyles } from "./styles";
import { useDispatch } from "react-redux";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";
import {
  EXLY_GLOBAL__CUSTOM_BACK_NAVIGATION,
  EXLY_GLOBAL__SET_PAGE_TITLE,
  EXLY_GLOBAL__SET_SHOW_APP_BAR,
  EXLY_GLOBAL__SET_SHOW_BOTTOM_NAV,
} from "redux/actions";

import { whatsappTabsMapping } from "./constants";
import { InitialSegmentUsedWA } from "./item/components/InitialSegmentUsedWA";
import { getParamValueFromRouteProps } from "utils/Utils";
import { RESET_TEMPLATE_VALUES } from "../redux/actions";
import { useWhatsAppHelpers } from "../useWhatsAppHelpers";
import { whatsapp_broadcasts_apis } from "../whatsappBroadCast.api";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { whatsappListProps } from "./BroadCastList";
import { getWhatsappWhiteLabelData } from "../whatsappBroadcastStorageUtils";
import { Header } from "common/Components/Header";
import { WHATSAPP_BROADCASTS_LEARN_MORE_HREFS } from "../whatsappBroadcast.constants";
import moduleStyles from "./WhatsappListLayout.module.css";
import useAppLayoutControl from "hooks/useAppLayoutControl";

//Refractored code and moved to layout, most of the code is old code from index.js
const WhatsappListLayout = ({ tabValue, props, children }) => {
  const isDesktop = useDesktopMediaQuery();
  const history = useHistory();
  const { loading } = useListController(whatsappListProps);
  const [showInitialSegmentModal, setShowInitialSegmentModal] =
    React.useState(false);
  const { handleCreateTemplate } = useWhatsAppHelpers();

  const styles = useStyles({ isDesktop });

  const hasWriteAccess = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.WHATSAPP_BROADCASTS
  );
  const canReadSegments = !orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SECTIONS.SEGMENTATION
  );

  const whatsappWhiteLabelData = getWhatsappWhiteLabelData();
  const { checkIsAiSensyBlockedAfterEnabling } = useWhatsAppHelpers();

  const disableWhatsappBroadCastCreation =
    !hasWriteAccess ||
    checkIsAiSensyBlockedAfterEnabling(whatsappWhiteLabelData);

  const dispatch = useDispatch();
  const checkSegment = getParamValueFromRouteProps(props, "check_segment");

  const handleCreate = () => {
    dispatch({ type: RESET_TEMPLATE_VALUES });
    history.push("/whatsapp-broadcasts/select");
  };

  React.useEffect(() => {
    if (checkSegment && canReadSegments) {
      setShowInitialSegmentModal(true);
    }
  }, []);

  useAppLayoutControl({
    values: {
      learnMoreHref: WHATSAPP_BROADCASTS_LEARN_MORE_HREFS.WHATSAPP_BROADCASTS,
    },
  });

  useEffect(() => {
    dispatch({
      type: EXLY_GLOBAL__SET_PAGE_TITLE,
      payload: "WhatsApp Broadcast",
    });
    dispatch({
      type: EXLY_GLOBAL__CUSTOM_BACK_NAVIGATION,
      payload: () => {
        history.replace(`${app_route_ids[[app_route_keys.sales_marketing]]}`);
      },
    });
    dispatch({ type: EXLY_GLOBAL__SET_SHOW_APP_BAR, payload: isDesktop });
    dispatch({ type: EXLY_GLOBAL__SET_SHOW_BOTTOM_NAV, payload: false });

    return () => {
      dispatch({ type: EXLY_GLOBAL__SET_PAGE_TITLE, payload: "" });
      dispatch({ type: EXLY_GLOBAL__SET_SHOW_APP_BAR, payload: true });
      dispatch({ type: EXLY_GLOBAL__SET_SHOW_BOTTOM_NAV, payload: true });
    };
  }, [dispatch, isDesktop]);

  useEffect(() => {
    try {
      // This is to prompt the back-end to sync campaign stats via whatsapp webhooks
      dataProvider.custom_request(
        whatsapp_broadcasts_apis.sync_campaign,
        "POST"
      );
    } catch (error) {
      console.log("Error in post campaign web hook: ", error);
    }
  }, []);

  return (
    <div className={`component-wrapper ${styles.root}`}>
      {isDesktop ? (
        <Header
          title="WhatsApp Broadcast"
          learnMoreHref={
            WHATSAPP_BROADCASTS_LEARN_MORE_HREFS.WHATSAPP_BROADCASTS
          }
          about="about creating and managing Whatsapp Broadcasts."
          right={
            <div className="d-flex">
              <Button
                size="large"
                color="secondary"
                onClick={handleCreateTemplate}
                disabled={!hasWriteAccess || disableWhatsappBroadCastCreation}
                className="mr-3"
              >
                Create Custom Template
              </Button>
              <Button
                className={styles.createButton}
                size="large"
                color="primary"
                onClick={handleCreate}
                disabled={disableWhatsappBroadCastCreation}
              >
                Create WhatsApp Broadcast
              </Button>
            </div>
          }
          classNames={{ container: moduleStyles.headerContainer }}
          hideBackButton
        />
      ) : (
        <div className={styles.header}>
          <div className={styles.createButtonContainer}>
            {isDesktop ? undefined : (
              <>
                {tabValue === whatsappTabsMapping.broadcast ? (
                  <Button
                    className={styles.createButton}
                    size="large"
                    color="primary"
                    fullWidth
                    onClick={handleCreate}
                    disabled={disableWhatsappBroadCastCreation}
                  >
                    Create WhatsApp Broadcast
                  </Button>
                ) : (
                  <Button
                    size="large"
                    color="primary"
                    fullWidth
                    onClick={handleCreateTemplate}
                    disabled={
                      !hasWriteAccess || disableWhatsappBroadCastCreation
                    }
                  >
                    Create Custom Template
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      )}

      <div className={styles.subHeader}>
        The Broadcast feature allows you to send a message to several contacts
        at once.
      </div>

      {loading ? <Loading isDesktop={isDesktop} /> : <>{children}</>}

      {showInitialSegmentModal ? <InitialSegmentUsedWA /> : null}
    </div>
  );
};

export default withComponentLibraryTheme(WhatsappListLayout);
