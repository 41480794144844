import React from "react";
import { makeStyles } from "@material-ui/core";
import { Tooltip } from "@my-scoot/component-library-legacy";
import InfoIcon from "@material-ui/icons/InfoOutlined";

import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
    itemData: {
        fontSize: 20,
        fontWeight: 700,
        marginBottom: 4,
    },
    itemTitle: {
        fontSize: "16px",
        color: "#272522",
    },
    toolTip: {
        color: "#777",
        marginLeft: 12,
    },
}));

const DataItem = ({ data, title, titleClassName, dataClassName, tooltip }) => {
    const classes = useStyles();

    return (
        <div>
            <div className={classnames(classes.itemData, dataClassName)}>
                {data}
                {tooltip ? (
                    <Tooltip title={tooltip} className={classes.toolTip}>
                        <InfoIcon className="info" fontSize="12" />
                    </Tooltip>
                ) : null}
            </div>
            <div className={classnames(classes.itemTitle, titleClassName)}>
                {title}
            </div>
        </div>
    );
};

export default DataItem;
