import ExlyTabsWrapper from "common/Components/ExlyTabsWrapper";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import EmptyScreens from "../EmptyScreens";
import { Tabs } from "@my-scoot/component-library-legacy";
import {
  whatsappSubTabsMapping,
  whatsappSubTabsValue,
} from "../../list/constants";
import ExlyTable from "common/Components/ExlyTable";
import { getTableConfigTemplates } from "./tableConfig";
import { useListController, useRefresh } from "react-admin";
import { is_empty } from "utils/validations";
import {
  MobileActionDrawer,
  TemplateStatusChip,
} from "./ListingTableComponents";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { useStyles } from "./listingPage.styles";
import { app_route_keys } from "constants/urlPaths";
import { navigateTo } from "utils/urlUtils";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";
import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";

const ListingPage = (props) => {
  const { id } = useParams();
  const [subTabValue, setSubTabValue] = React.useState(
    whatsappSubTabsValue[id]?.value
  );
  const { data } = useListController(props);
  const refresh = useRefresh();
  const isDesktop = useDesktopMediaQuery();

  const {
    isOnboardedPrevVal: isFeatureVisited,
    flagLoading: isFeatureFlagLoading,
    handleFeatureOnboardChange,
  } = useFeatureOnboardingChecker({
    featureKey: onboardingFeatureKeys.KNOWLEDGE_BASE.WHATSAPP_TEMPLATES,
    successCallback: (marked) => !marked && handleFeatureOnboardChange(true),
  });

  const handleChange = useCallback((event, newValue) => {
    const tabValue = newValue || whatsappSubTabsMapping.Approved;
    setSubTabValue(tabValue);
    navigateTo(app_route_keys.template_list, { args: tabValue });
    refresh();
  }, []);

  const styles = useStyles({ isDesktop });

  return (
    <div>
      <ExlyTabsWrapper styles={styles}>
        <Tabs
          key="level-one"
          value={subTabValue}
          onChange={handleChange}
          tabVariant="filled_primary"
          tabs={Object.values(whatsappSubTabsValue).sort((a, b) => {
            //We are sorting the tabs on the basis of the position
            if (a.Position > b.Position)
              return 1; // That they should be on the screen
            else return -1;
          })}
        />
      </ExlyTabsWrapper>
      {!is_empty(data) ? (
        <ExlyTable
          ra_props={{
            ...props,
          }}
          hasMobileFooter={true}
          columnConfig={getTableConfigTemplates()}
          layoutProps={{
            paddingDesktop: "0",
            paddingMobile: "0",
            paddingBottom: "120px",
            noMobileBoxShadow: true,
            showFixedBars: true,
          }}
          fieldsLeftPadded
          borderedFields
          drawerFieldsBordered
          fieldsAlignment="space-between"
          drawerFieldsAlignment="space-between"
          recordFooterVariant="secondary"
          primaryKey="id"
          renderSecondaryAction={(record) => (
            <TemplateStatusChip status={record?.approval_status} />
          )}
          customPrimaryAction={{
            renderCustomAction: (record) => (
              <MobileActionDrawer
                record={record}
                emptyText={"N/A"}
                label={"Actions"}
              />
            ),
          }}
        />
      ) : (
        <EmptyScreens
          loading={isFeatureFlagLoading}
          subTabValue={subTabValue}
          autoplay={!isFeatureFlagLoading && !isFeatureVisited}
        />
      )}
    </div>
  );
};

export default withComponentLibraryTheme(ListingPage);
