import React from "react";

import { Tooltip } from "@my-scoot/component-library-legacy";

import { useStyles } from "./DesktopRow.styles";

import DataItem from "../DataItem";

import { IconButton } from "@material-ui/core";

import { ReactComponent as CopyIcon } from "assets/images/copy.svg";
import WhatsappIcon from "assets/vectors/whatsap-broadcast/whatsapp_white.svg";
import { EditRounded as EditIcon } from "@material-ui/icons";

import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";

import classnames from "classnames";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import {
  campaignStepsData,
  WHATSAPP_CAMPAIGN_DESCRIPTIONS,
} from "ui/pages/whatsappBroadcast/whatsappBroadcast.constants";
import {
  getStatusChip,
  whatsappCampaignStatusChoices,
} from "../../../constants";
import RenderPhoneNumber from "../RenderPhoneNumber";

const DesktopRow = ({
  data,
  time,
  alternate,
  recipients,
  handlePreview,
  handleDuplicate,
  hasWriteAccess,
  onDelete,
  handleEdit,
  type,
  disableAction,
  getTitle,
}) => {
  const classes = useStyles({});

  const isDraft = type === whatsappCampaignStatusChoices.Drafts;
  const isScheduled = type === whatsappCampaignStatusChoices.Scheduled;

  return (
    <div
      className={classnames(
        classes.root,
        alternate && classes.dark,
        !hasWriteAccess && "disable_pointer"
      )}
    >
      <img
        className={classes.emailIcon}
        src={WhatsappIcon}
        alt="whatsapp main logo"
      />

      <div className={classes.content}>
        <div className={classes.header}>
          <div className={classes.leftContainer}>
            <div className={classes.mailTo}>
              <div
                className={classes.recipients}
                onClick={isDraft ? handleEdit : handlePreview}
              >
                To: {data?.audience?.segment?.title || recipients}
              </div>
              {getStatusChip({ type, classes })}
            </div>

            <div className={classes.subject}>Campaign Name: {data.title}</div>
            {getTitle({ time, isDraft, isScheduled, type })}
            {!isDraft && !isScheduled && <RenderPhoneNumber data={data} />}
          </div>

          <div className={classes.rightContainer}>
            <div className={classes.iconContainer}>
              <Tooltip
                arrow
                color="primary"
                placement="top"
                title={isDraft ? "Edit broadcast" : "View broadcast"}
              >
                <IconButton
                  className={classes.iconButton}
                  disabled={isDraft && disableAction}
                  onClick={isDraft ? handleEdit : handlePreview}
                >
                  {isDraft ? <EditIcon /> : <VisibilityIcon />}
                </IconButton>
              </Tooltip>
              <Tooltip
                arrow
                color="primary"
                placement="top"
                title="Duplicate this broadcast"
              >
                <IconButton
                  className={classes.iconButton}
                  onClick={handleDuplicate}
                  disabled={disableAction}
                >
                  <CopyIcon
                    className={classnames(
                      classes.copyIcon,
                      disableAction && classes.copyIconDisabled
                    )}
                  />
                </IconButton>
              </Tooltip>
              {type !== whatsappCampaignStatusChoices.Completed && (
                <IconButton
                  className={classnames(classes.iconButton, classes.deleteIcon)}
                  onClick={onDelete}
                >
                  <DeleteRoundedIcon />
                </IconButton>
              )}
            </div>
          </div>
        </div>
        {!isDraft && !isScheduled ? (
          <>
            <hr className={classes.divider} />
            <div className={classes.bottomDataContainer}>
              <div className={classes.dataContainer}>
                {[
                  [
                    "Sent to",
                    data?.sent_count,
                    classes.sentTo,
                    "Number of users to whom the campaign was initiated",
                  ],
                  [
                    "Delivered to",
                    data?.delivered_count,
                    classes.deliveredTo,
                    "Number of users to whom the message was delivered",
                  ],
                  [
                    "Opened by",
                    data?.read_count,
                    classes.openedBy,
                    "Number of users who read the message (Read receipts of the user need to be enabled)",
                  ],
                  [
                    "Opened rate(%)",
                    data?.sent_count
                      ? `${parseFloat(
                          (data?.read_count / data?.sent_count) * 100
                        )?.toFixed(2)}%`
                      : "N/A",
                    classes.openedRate,
                  ],
                ].map(([title, value, className, tooltip], i) => (
                  <>
                    {i > 0 ? (
                      <div className={classes.horizontalDivider} />
                    ) : null}
                    <DataItem
                      dataClassName={className}
                      title={title}
                      data={value}
                      tooltip={tooltip}
                    />
                  </>
                ))}
              </div>
              <div className={classes.campaignDataContainer}>
                <Tooltip
                  arrow
                  color="primary"
                  placement="top"
                  title={WHATSAPP_CAMPAIGN_DESCRIPTIONS[`${data?.status}`]}
                >
                  <InfoIcon />
                </Tooltip>
                <div
                  className={classnames(
                    classes.campaignStep,
                    classes[campaignStepsData[`${data?.status}`]?.className]
                  )}
                >
                  {campaignStepsData[`${data?.status}`]?.label}
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default DesktopRow;
