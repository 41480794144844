// Third party imports
import React from "react";
import ExlyModal from "common/Components/ExlyModal";
import classes from "../../../../customers/Segments/components/TableComponents/TableFields.module.css";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";
import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";
import { useHistory } from "react-router";

export const InitialSegmentUsedEmail = () => {
  const [showModal, setShowModal] = React.useState(false);

  const history = useHistory();

  const { isOnboarded, flagLoading, handleFeatureOnboardChange } =
    useFeatureOnboardingChecker({
      featureKey: onboardingFeatureKeys.SEGMENTS.FIRST_EMAIL_USED_SEGMENTAION,
    });

  React.useEffect(() => {
    if (flagLoading) return;

    if (!isOnboarded) {
      setTimeout(() => {
        setShowModal(true);
      }, 500);
    }
  }, [isOnboarded, flagLoading]);

  const onClose = () => {
    setShowModal(false);
    handleFeatureOnboardChange(true);
  };

  const redirectToSegments = () => {
    handleFeatureOnboardChange(true);
    history.push("/host/segments/list/customers");
  };

  return (
    <>
      <ExlyModal
        title="Way to go!"
        mobile_modal_props={{
          secondaryAction: undefined,
        }}
        primaryBtnFullWidth={true}
        showSecondaryBtn={false}
        open={showModal}
        primaryBtnText="Go to 'Segments'"
        onClose={onClose}
        onPrimaryBtnClick={redirectToSegments}
      >
        <div className={classes.modalContainer}>
          <div className={classes.modalHeader}>
            <img
              alt="dynamic_sparkle"
              height={180}
              width={350}
              className={classes.imageStats}
              src={require("assets/images/segments/desktop/email_segment_used.png")}
            />{" "}
          </div>
          <p className={classes.modalLabel}>
            <span role="img" aria-label="Congratulations">
              🎉
            </span>{" "}
            <b>
              Congratulations on taking your first step to increase your
              conversions!
            </b>{" "}
            You can view the performance of these segments on the segments page.
          </p>
        </div>
      </ExlyModal>
    </>
  );
};
