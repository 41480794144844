import React from "react";
import { dataProvider } from "data";
import { useCallback } from "react";
import { whatsappSubTabsMapping, whatsappTabsMapping } from "./list/constants";
import { useHistory } from "react-router-dom";
import { apiMethods } from "data/api.constants";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { is_empty } from "utils/validations";
import { WhatsappTemplateNameMapping } from "./create/CustomTemplates/moduleConstants";
import { EmptyStateImage } from "./create/Preview/components/EmptyStateImage";
import {
  HEADER_MEDIA_OPTIONS,
  green_tick_status_consts,
  whatsapp_platform_choices_consts,
  whitelabelled_whatsapp_status_constants,
} from "./whatsappBroadcast.constants";
import { EmptyStateVid } from "./create/Preview/components/EmptyStateVideo";
import { EmptyStateDoc } from "./create/Preview/components/EmptyStateDoc";
import { RichTextEditorUtils } from "@my-scoot/component-library-legacy";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { whatsapp_broadcasts_apis } from "./whatsappBroadCast.api";
import { getStringFromHtml, isRequestSuccessful } from "utils/Utils";
import StringToHtml from "./create/StringToHtml";
import {
  getWhatsappWhiteLabelData,
  setWhatsappWhiteLabelData,
} from "./whatsappBroadcastStorageUtils";
import { WHATSAPP_TEMPLATE_LANGUAGE_DEFAULT } from "features/WhatsappTemplate/modules/LanguageSelectionDropdown/LanguageSelectionDropdown.constants";
import { BUTTON_TYPES } from "features/WhatsappTemplate/WhatsappTemplate.constants";
import { navigateTo } from "utils/urlUtils";

const { getEditorStateFromHtml } = RichTextEditorUtils;

export const useWhatsAppHelpers = () => {
  const history = useHistory();
  const { notify } = useNotifications();
  const hasWhatsappWriteAccess = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.WHATSAPP_BROADCASTS
  );

  const handleTabsChange = useCallback((event, newValue) => {
    newValue === whatsappTabsMapping.templates
      ? navigateTo(app_route_keys.template_list, {
          args: whatsappSubTabsMapping.Approved,
        })
      : history.push(`${app_route_ids[[app_route_keys.whatsapp_broadcast]]}`);
  }, []);

  const createWhatsappTemplates = async (values, approvalStatus) => {
    try {
      const valuesToSend = { ...values, approval_status: approvalStatus };
      delete valuesToSend.description;

      if (is_empty(valuesToSend?.cta_data)) delete valuesToSend.cta_data;

      if (is_empty(valuesToSend?.header_data.headerType))
        delete valuesToSend.header_data.headerType;

      delete valuesToSend.cta_radio;

      const response = await dataProvider.custom_request(
        whatsapp_broadcasts_apis.create_new_template,
        apiMethods.POST,
        { ...valuesToSend }
      );
      if (isRequestSuccessful(response.status)) {
        notify(
          "Template created successfully",
          notification_color_keys.success
        );
        return response;
      }
    } catch (err) {
      notify(
        err.message || "Error while creating the template",
        notification_color_keys.error
      );
    }
  };

  const hasVariableOccurrences = (str, variables) => {
    const pattern = /\{\{([^{}]+)\}\}/g;
    const matches = str?.match(pattern);

    if (!matches) {
      return false;
    }

    for (const match of matches) {
      const variable = match.substring(2, match.length - 2);
      const found = variables.find((v) => v.value === `{{${variable}}}`);
      if (!found) {
        return true;
      }
    }

    return false;
  };

  const handleCreateTemplate = () => {
    if (!hasWhatsappWriteAccess) return;
    // openDefaultValuesModal();
    history.push(`${app_route_ids[app_route_keys.whatsapp_template]}`);
  };

  const updateWhatsappTemplates = async (values, approvalStatus, id) => {
    try {
      const valuesToSend = { ...values, approval_status: approvalStatus };
      delete valuesToSend.description;

      if (is_empty(valuesToSend?.footer)) valuesToSend.footer = "";

      if (is_empty(valuesToSend?.header_data.headerType))
        delete valuesToSend.header_data.headerType;

      delete valuesToSend.cta_radio;

      valuesToSend.body = getStringFromHtml(valuesToSend.body);

      const response = await dataProvider.custom_request(
        whatsapp_broadcasts_apis.update_template,
        apiMethods.POST,
        { updated_values: { ...valuesToSend }, template_uuid: id }
      );
      if (isRequestSuccessful(response.status)) {
        notify(
          "Template Updated successfully",
          notification_color_keys.success
        );
        return response;
      }
    } catch (err) {
      notify(
        err.message || "Error while updating the template",
        notification_color_keys.error
      );
    }
  };

  const getTemplateById = async (id) => {
    try {
      const { data, status } = await dataProvider.custom_request(
        whatsapp_broadcasts_apis.get_templates,
        apiMethods.GET,
        {
          template_uuid: id,
        }
      );

      if (isRequestSuccessful(status)) {
        return data;
      }
    } catch (err) {
      notify(
        err?.message || "Error while getting templates",
        notification_color_keys.error
      );
      console.log(err.message);
    }
  };

  const getWhatsappWhiteLabelDetails = async (params = {}) => {
    const { fetch_latest_profile = false } = params;
    try {
      const { data, status } = await dataProvider.custom_request(
        whatsapp_broadcasts_apis.get_whatsapp_white_label_details,
        apiMethods.GET,
        {
          fetch_latest_profile,
        }
      );

      if (isRequestSuccessful(status) && !is_empty(data)) {
        setWhatsappWhiteLabelData(data);
        return data || {};
      }
      return {};
    } catch (err) {
      notify(
        err?.message || "Error while  fetching integration status",
        notification_color_keys.error
      );
      console.log(err.message);
      /**@dev dont remove this, need an object to be recieved in the component in case of failure as well as success  */
      return {};
    }
  };

  const fetchMetaUrl = async () => {
    const { data, status } = await dataProvider.custom_request(
      whatsapp_broadcasts_apis.get_meta_url,
      apiMethods.POST
    );

    if (!isRequestSuccessful(status)) throw new Error(`: ${data} ${status}`);

    return data?.embedded_signup_link;
  };

  /**
   *
   * @param {*} template
   * @returns Function returns parsed values for the api call
   */
  const parseDataForForm = (template) => {
    const stringToConvert = template.body || "";

    // Converting string to html
    const style = "margin:0;min-height:18px";
    const htmlBody = StringToHtml({ stringToConvert, style });

    const localParsedValues = {
      display_title: template.display_title,
      body: template.html_body || template.body,
      cta_data: !is_empty(template.cta_data)
        ? template.cta_data.map((cta, idx) => {
            return {
              id: idx + 1,
              ...cta,
            };
          })
        : [],
      description: !is_empty(template.html_body)
        ? getEditorStateFromHtml(template.html_body)
        : getEditorStateFromHtml(htmlBody.props.dangerouslySetInnerHTML.__html),
      header_data: {
        text: template.header.text,
        type: template.header.type,
      },
      template_type: template.template_type,
      locale: template.locale || WHATSAPP_TEMPLATE_LANGUAGE_DEFAULT.value,
      template_type_text:
        WhatsappTemplateNameMapping[template.template_type] || "",
      variables: template.variables,
      footer: template.footer,
      cta_radio:
        template?.cta_data?.length > 0
          ? BUTTON_TYPES.CALL_TO_ACTION.value
          : BUTTON_TYPES.NONE.value,
    };

    return localParsedValues;
  };

  const goBack = useCallback(() => {
    window.history.go(-1);
  }, []);

  const renderMediaOptions = {
    [HEADER_MEDIA_OPTIONS.IMG]: <EmptyStateImage />,
    [HEADER_MEDIA_OPTIONS.VID]: <EmptyStateVid />,
    [HEADER_MEDIA_OPTIONS.DOC]: <EmptyStateDoc />,
  };

  const submitGreenTickApplication = async (
    payload,
    successCallback = () => {}
  ) => {
    const whatsappWhiteLabelData = getWhatsappWhiteLabelData();

    const payloadToSend = {
      email: payload.email,
      region: payload.region,
      facebook_business_manager_id: payload.facebook_business_manager_id,
      whatsapp_number: payload.whatsapp_number,
      display_name: payload.display_name,
      country: payload.country,
      exly_business_uuid: whatsappWhiteLabelData.exly_business_uuid,
      exly_project_uuid: whatsappWhiteLabelData.exly_project_uuid,
      company_details: {
        company_name: payload.company_name,
        company_website: payload.company_website,
        company_facebook_page: payload.company_facebook_page,
        alternate_business_names: payload.alternate_business_names,
        business_name_in_source_language:
          payload.business_name_in_source_language,
        market_reach_detail: payload.market_reach_detail,
        product_and_services: payload.product_and_services,
        number_of_employees: payload.number_of_employees,
        client_base_size: payload.client_base_size,
        company_revenue: payload.company_revenue,
        awards_details: payload.awards_details,
        media_coverage_details: payload.media_coverage_details,
        official_business_account_approval_reason:
          payload.official_business_account_approval_reason,
        other_details: payload.other_details,
      },
    };

    try {
      const { data, status } = await dataProvider.custom_request(
        whatsapp_broadcasts_apis.submit_green_tick_application,
        apiMethods.POST,
        payloadToSend
      );

      if (isRequestSuccessful(status)) {
        const whatsappData = {
          ...whatsappWhiteLabelData,
          green_tick_status: green_tick_status_consts.SUBMITTED,
        };
        setWhatsappWhiteLabelData(whatsappData);
        notify("Form submitted successfully!", notification_color_keys.success);
        successCallback();
        return data;
      }
    } catch (err) {
      notify(
        err?.message || "Error while submitting application",
        notification_color_keys.error
      );
      console.log(err.message);
    }
  };

  const getDisplayPhoneNumber = (data) => {
    const phoneNumber = `${data.country_code}${data.phone_number}`;
    return `${
      data.platform !== whatsapp_platform_choices_consts.AISENSY
        ? "Exly's"
        : "Personal"
    } number (${phoneNumber})`;
  };

  const checkIsAiSensyBlockedAfterEnabling = (whatsappWhiteLabelData) => {
    return (
      checkIsWhatsappWhiteLabelBlocked(
        whatsappWhiteLabelData.whitelabelled_whatsapp_status
      ) && whatsappWhiteLabelData.has_migrated_to_aisensy
    );
  };

  const checkIsWhatsappWhiteLabelBlocked = (value) => {
    return [
      whitelabelled_whatsapp_status_constants.BLOCKED,
      whitelabelled_whatsapp_status_constants.FAILED,
    ].includes(value);
  };
  return {
    handleTabsChange,
    createWhatsappTemplates,
    getTemplateById,
    parseDataForForm,
    updateWhatsappTemplates,
    renderMediaOptions,
    goBack,
    hasWhatsappWriteAccess,
    handleCreateTemplate,
    hasVariableOccurrences,
    getWhatsappWhiteLabelDetails,
    fetchMetaUrl,
    submitGreenTickApplication,
    getDisplayPhoneNumber,
    checkIsAiSensyBlockedAfterEnabling,
    checkIsWhatsappWhiteLabelBlocked,
  };
};
