/* eslint-disable react/no-array-index-key */
import React from "react";
import { useHistory } from "react-router-dom";

import { MobilePopover } from "@my-scoot/component-library-legacy";

import { ReactComponent as CopyIcon } from "assets/images/copy.svg";
import VisibilityIcon from "@material-ui/icons/Visibility";

import DesktopRow from "./components/DesktopRow/DesktopRow";
import MobileRow from "./components/MobileRow/MobileRow";

import { useStyles } from "./styles";
import { handleDateFormat } from "utils/Utils";
import { useDispatch } from "react-redux";
import { SET_TEMPLATE_ACTION_TYPE } from "../../redux/actions";
import { templateActionTypes } from "../../create/CustomTemplates/moduleConstants";
import { whatsappCampaignStatusChoices } from "../constants";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import { EditRounded as EditIcon } from "@material-ui/icons";
import ExlyDeleteModal from "common/Components/ExlyDeleteModal/ExlyDeleteModal";
import { useRefresh } from "react-admin";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { dataProvider } from "data";
import { whatsapp_broadcasts_apis } from "../../whatsappBroadCast.api";
import { getWhatsappWhiteLabelData } from "../../whatsappBroadcastStorageUtils";
import classnames from "classnames";
import styles from "./styles.module.css";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { appendUrlParams } from "utils/urlUtils";
import { SECONDARY_ACTIONS, preview } from "../../whatsappBroadcast.constants";
import { useWhatsAppHelpers } from "../../useWhatsAppHelpers";

const getTime = (timestamp) => {
  return handleDateFormat(timestamp);
};

const ListItem = ({ id, data, isDesktop, hasWriteAccess, subTabValue }) => {
  const classes = useStyles({ isDesktop });
  const history = useHistory();
  const [openModal, setOpenModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);
  const refresh = useRefresh();
  const dispatch = useDispatch();
  const { notify } = useNotifications();
  const whatsappWhiteLabelData = getWhatsappWhiteLabelData();
  const { checkIsAiSensyBlockedAfterEnabling } = useWhatsAppHelpers();
  const isWhatsappWhiteLabelBlockedAfterEnabling =
    checkIsAiSensyBlockedAfterEnabling(whatsappWhiteLabelData);

  const getTitle = ({ time, isDraft, isScheduled, type }) => {
    const titleMapping = {
      [whatsappCampaignStatusChoices.Drafts]: (
        <div className={classes.dateTime}>Edited {time}</div>
      ),
      [whatsappCampaignStatusChoices.Scheduled]: (
        <div className={classes.scheduled}>
          Scheduled for <span className={classes.dateTime}>{time}</span>{" "}
        </div>
      ),
    };

    return isDraft || isScheduled ? (
      titleMapping[`${type}`]
    ) : (
      <div className={classes.dateTime}>Sent {time}</div>
    );
  };

  const handlePreview = () => {
    dispatch({
      type: SET_TEMPLATE_ACTION_TYPE,
      payload: templateActionTypes.DynamicRoute,
    });
    const path =
      app_route_ids[app_route_keys.manage_communications_whatsapp_publish];
    let params = {
      action: preview,
      campaign_uid: data.uuid,
    };
    if (data?.is_custom_template) {
      params = {
        ...params,
        customTemplate: data?.is_custom_template,
      };
    }
    history.push(appendUrlParams(path, params));
  };

  const handleDuplicate = () => {
    dispatch({
      type: SET_TEMPLATE_ACTION_TYPE,
      payload: templateActionTypes.DynamicRoute,
    });

    const path =
      app_route_ids[app_route_keys.manage_communications_whatsapp_select];
    let params = {
      action: SECONDARY_ACTIONS.DUPLICATE,
      campaign_uid: data.uuid,
    };
    if (data?.is_custom_template) {
      params = {
        ...params,
        customTemplate: data?.is_custom_template,
      };
    }
    history.push(appendUrlParams(path, params));
  };

  // Old code only moved not revamped
  const handleEdit = () => {
    dispatch({
      type: SET_TEMPLATE_ACTION_TYPE,
      payload: templateActionTypes.DynamicRoute,
    });

    const path =
      app_route_ids[app_route_keys.manage_communications_whatsapp_select];
    let params = {
      action: SECONDARY_ACTIONS.EDIT,
      campaign_uid: data.uuid,
    };
    if (data?.is_custom_template) {
      params = {
        ...params,
        customTemplate: data?.is_custom_template,
      };
    }
    history.push(appendUrlParams(path, params));
  };

  const handleDelete = (template) => {
    setSelectedTemplate(template);
    setShowDeleteModal(true);
  };

  const deleteCampaign = async (selectedCampaign, onClose) => {
    try {
      await dataProvider.custom_request(
        `${whatsapp_broadcasts_apis.delete_campaign}/${selectedCampaign}`,
        "POST"
      );
      onClose();
      notify("Campaign deleted successfully", notification_color_keys.success);
    } catch {
      notify("Something went wrong!", notification_color_keys.error);
    }
  };

  const duplicateOption = [
    {
      icon: (
        <CopyIcon
          className={classnames(
            styles.copyIcon,
            isWhatsappWhiteLabelBlockedAfterEnabling && styles.copyIconDisabled
          )}
        />
      ),
      label: "Duplicate Broadcast",
      onClick: handleDuplicate,
      disableAction: isWhatsappWhiteLabelBlockedAfterEnabling,
    },
  ];

  const options = [
    {
      icon: <EditIcon disabled={isWhatsappWhiteLabelBlockedAfterEnabling} />,
      label: "Edit Broadcast",
      onClick: handleEdit,
      disableAction: isWhatsappWhiteLabelBlockedAfterEnabling,
    },
    ...duplicateOption,
    {
      icon: <DeleteRoundedIcon />,
      label: "Delete Broadcast",
      onClick: () => handleDelete(data?.uuid),
    },
  ];

  const popoverOptionsTypeMapping = {
    [whatsappCampaignStatusChoices.Completed]: [
      {
        icon: <VisibilityIcon />,
        label: "View Broadcast",
        onClick: handlePreview,
      },
      ...duplicateOption,
    ],
    [whatsappCampaignStatusChoices.Scheduled]: options,
    [whatsappCampaignStatusChoices.Drafts]: options,
  };

  // Determine whether the given 'id' is an odd number for alternate processing
  const alternate = Number.parseInt(id) % 2 !== 0;

  const audience = data?.audience || {};
  const groups = (audience.groups || []).map((g) => g.display_name);
  const individual = audience.individual || [];
  //Checking if gorups key exist before mapping into it
  const recipientList = [...groups, ...individual];

  const rowConfig = {
    data,
    time: getTime(
      subTabValue === whatsappCampaignStatusChoices.Scheduled
        ? data.scheduled_time
        : data.updated_at
    ),
    alternate,
    recipients: recipientList
      .slice(0, 2)
      .join(", ")
      .concat(recipientList?.length > 2 ? " ..." : ""),
    onMoreClick: () => setOpenModal(true),
    handlePreview,
    handleDuplicate,
    handleEdit,
    hasWriteAccess,
    type: subTabValue,
    onDelete: () => handleDelete(data?.uuid),
    disableAction: isWhatsappWhiteLabelBlockedAfterEnabling,
    getTitle: getTitle,
  };

  return (
    <>
      {isDesktop ? <DesktopRow {...rowConfig} /> : <MobileRow {...rowConfig} />}
      <MobilePopover
        paperClassName={classes.paperClassName}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        {popoverOptionsTypeMapping[`${subTabValue}`]?.map((option, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={classnames(
              classes.option,
              option.disableAction && styles.disabledOption
            )}
            onClick={!option.disableAction && option.onClick}
          >
            {option.icon}&nbsp;
            {option.label}
          </div>
        ))}
      </MobilePopover>
      <ExlyDeleteModal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title="Confirm Delete"
        description={`Are you sure you want to delete this broadcast?`}
        onDelete={() => {
          deleteCampaign(selectedTemplate, () => {
            refresh();
            setShowDeleteModal(false);
          });
        }}
      />
    </>
  );
};

export default ListItem;
