import { makeStyles } from "@material-ui/core";

export const useStyles = ({ isMobile }) =>
  makeStyles(() => ({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      maxWidth: "400px",
      padding: "8px 0 16px 20px",
      margin: "auto",
      maxHeight: isMobile ? "100vh" : "calc(100vh - 50px)",
      height: "100%",
      "& *": {
        fontFamily: "unset",
      },

      ...(isMobile && {
        overflow: "hidden",
        position: "fixed",
        top: 0,
        zIndex: 11,
        height: "100%",
        backgroundColor: "#fffe",
        backdropFilter: "blur(4px)",
        maxWidth: "unset",
        width: "100%",
        padding: "60px 12px 90px",
      }),
    },
    footerText: {
      color: "rgba(39, 37, 34, 0.6)",
      marginBottom: "12px",
      marginTop: "14px",
    },
    buttonsContainer: {
      borderTop: "0.5px solid rgba(39, 37, 34, 0.1)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "8px",
      gap: "8px",
    },
    buttonWrapper: {
      display: "flex",
      color: "#0088EA",
      gap: "4px",
      alignItems: "center",
    },
    header: {
      margin: `8px auto`,
      textAlign: "center",
    },
    headerText: {
      fontWeight: "bold",
      marginBottom: "8px",
    },
    title: {
      fontSize: "20px",
      fontWeight: 700,
    },
    subTitle: {
      fontSize: "15px",
      color: "#272522b2",
    },
    phoneContainer: {
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.28)",
      borderRadius: "32px",
      padding: "40px 10px 72px",
      maxWidth: "min(300px, 85vw)",
      width: 280,
      margin: "0 auto auto",

      flexGrow: 1,

      display: "flex",
      flexDirection: "column",
      maxHeight: 550,
    },
    screen: {
      backgroundColor: "#00b7794d",
      borderRadius: "16px",
      display: "flex",
      flexDirection: "column",
      height: "100%",

      position: "relative",
      backgroundImage:
        "url('https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png')",
    },
    whatsappChatBody: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",

      position: "absolute",
      top: 52,
      overflowY: "scroll",
      bottom: 0,
      width: "100%",
    },
    whatsappChatBodyText: {
      backgroundColor: "#fff",
      padding: "20px 12px",
      margin: "24px 12px",
      whiteSpace: "pre-wrap",
      maxWidth: "80%",
      borderRadius: "8px",
      fontSize: "14px",
      lineHeight: 1.3,
      wordBreak: "break-all",

      "& code": {
        fontSize: "100%",
        fontFamily: "inherit",
        transition: "0.2s ease all",
        display: "inline-flex",
        wordBreak: "break-word",
        maxWidth: "100%",
        "&:hover": {
          backgroundColor: "#e83e8c22",
          transform: "translateY(-1px)",
        },
      },
    },
    whatsappChatBodyTextPreview: {
      color: "#777",
      padding: "56px 12px",
      textAlign: "center",
    },

    imagePreview: {
      margin: "-14px -7px 10px -7px",
      height: "104px",
      width: "189px",
      ["@media (max-width:780px)"]: {
        width: "198px",
      },
      marginBottom: "5px",
      borderRadius: "6px",
      objectFit: "cover",
    },

    imageContainer: {
      textAlign: "center",
      width: "189px",
      ["@media (max-width:780px)"]: {
        width: "198px",
      },
      maxWidth: "calc(100% + 14px)",
      overflow: "hidden",
      padding: "20% 0px 0px 0px !important",
      height: "104px",
      background: "#F7F7F7",
      color: "#AEAEAE",
      borderRadius: "6px",
      margin: "-14px -7px 10px -7px",

      fontSize: "12px",
    },

    uploadDocContainer: {
      transform: "translate(-7px, -15px)",
      background: "#F7F7F7",
      height: "48px",
      // maxWidth: "198px",
      maxWidth: "calc(100% + 14px)",

      width: "198px",
      padding: "5%",
      paddingTop: "11px",
      fontSize: "12px",
      borderRadius: "6px",

      color: "#AEAEAE",
    },

    uploadedDocContainer: {
      transform: "translate(-7px, -15px)",
      background: "#F7F7F7",
      height: "48px",
      // maxWidth: "196px",
      maxWidth: "calc(100% + 14px)",

      width: "196px",
      padding: "5%",
      paddingTop: "7%",
      borderRadius: "6px",
      fontSize: "12px",
      color: "#e74c3c",
    },

    docTextUploaded: {
      marginTop: "5px",
      marginLeft: "5px",
      transform: "translate(0px, 4px)",
      width: "100px",
      display: "inline-block",
      color: "#272522",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },

    docText: {
      marginTop: "5px",
      marginLeft: "5px",
    },

    downloadIcon: {
      float: "right",
      color: "#AEAEAE",
    },

    vidcontainer: {
      position: "relative",
    },

    overlayPlayButton: {
      position: "absolute",
      top: "29%",
      left: "42%",
      zIndex: 1,
    },

    iconPLay: {
      scale: 1.2,
    },
  }));
