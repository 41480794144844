import React from "react";

import { Tabs } from "@my-scoot/component-library-legacy";

import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";

import ExlyTabsWrapper from "common/Components/ExlyTabsWrapper";
import { whatsappTabsMapping, whatsappTabsValue } from "./constants";
import { useWhatsAppHelpers } from "../useWhatsAppHelpers";

import ListingPage from "../Components/ListingPage";
import WhatsappListLayout from "./WhatsappListLayout";

const ListWhatsappTemplates = (props) => {
  const tabValue = whatsappTabsMapping.templates;

  const { handleTabsChange } = useWhatsAppHelpers();

  return (
    <WhatsappListLayout props={props}>
      <ExlyTabsWrapper>
        <Tabs
          key="level-one"
          value={tabValue}
          onChange={handleTabsChange}
          tabs={whatsappTabsValue}
        />
      </ExlyTabsWrapper>
      <ListingPage {...props} />
    </WhatsappListLayout>
  );
};

export default withComponentLibraryTheme(ListWhatsappTemplates);
