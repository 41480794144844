import { EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS } from "features/Common/modules/ExlyEmptyState/constants/ExlyEmptyState.constants";
import ExlyEmptyState from "features/Common/modules/ExlyEmptyState/ExlyEmptyState";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import React from "react";
import { whatsappSubTabsMapping } from "../../list/constants";
import { WHATSAPP_BROADCASTS_LEARN_MORE_HREFS } from "../../whatsappBroadcast.constants";
import { emptyScreensMapping } from "./emptyScreens.helper";
import { useIsDesktop } from "@my-scoot/exly-react-component-lib";

const EmptyScreens = ({ subTabValue, autoplay, loading }) => {
  const isDesktop = useIsDesktop();
  const screenToRender = subTabValue
    ? emptyScreensMapping[`${subTabValue}`]
    : emptyScreensMapping[[whatsappSubTabsMapping.Drafts]];

  const { heading, content, image, imageMobile, videoSrc } =
    screenToRender || {};

  return (
    <ExlyEmptyState
      isLoading={loading}
      title={heading}
      description={content}
      imgSrc={isDesktop ? image : imageMobile}
      videoSrc={videoSrc}
      secondaryCtaProps={{
        variant: EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS.learn_more,
        learnMoreHref: WHATSAPP_BROADCASTS_LEARN_MORE_HREFS.WHATSAPP_BROADCASTS,
      }}
      playerProps={{ playing: autoplay }}
      className="mt-4"
    />
  );
};

export default withComponentLibraryTheme(React.memo(EmptyScreens));
