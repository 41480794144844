import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  info_field_wrapper: {
    color: theme?.palette?.primary?.main,
    fontSize: "14px",
    lineHeight: "16px",
    display: "flex",
    flexDirection: ({ isDesktop }) => (isDesktop ? "row" : "column"),
    gap: ({ isDesktop }) => (isDesktop ? "30px" : "0px"),
    alignItems: ({ isDesktop }) => (isDesktop ? "center" : "baseline"),

    "& svg": {
      width: "19.21px",
    },
  },
  tabsWrapperScroller: {
    width: "300px",
  },
  mobileWrapper: {
    display: "flex",
    padding: "20px",
    color: "#272522",
    gap: "11px",
    alignItems: "center",
    borderBottom: "1px solid #E7E6E4",
    fontSize: "16px",
    width: "100%",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  labelWrapper: {
    color: "#493AB1",
    fontWeight: "500",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginRight: "5px",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  deleteMobileWrapper: {
    display: "flex",
    padding: "20px",
    color: "#AA2822",
    fontSize: "16px",

    gap: "11px",
    alignItems: "center",
    width: "100%",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  template: {
    borderRadius: "4px",
    padding: "8px 4px",
  },
  wrap: {
    padding: "8px 0 36px 0",
  },
  actionsDivWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },

  template_1: {
    background: "#D1D1D1 !important",
  },
  template_2: {
    background: "#FFAB00 !important",
    color: "#FFFFFF !important",
  },
  template_3: {
    background: "#FFAB00 !important",
    color: "#FFFFFF !important",
  },

  template_4: {
    background: "#00B779 !important",
    color: "#FFFFFF !important",
  },
  template_5: {
    background: "#BF2600 !important",
    color: "#FFFFFF !important",
  },
  disabledAction: {
    pointerEvents: "none",
    opacity: 0.5,
    "&:hover": {
      cursor: "not-allowed !important",
      textDecoration: "none !important",
    },
  },
  actionStyle: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
