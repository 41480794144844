import ExlyImage from "common/Components/ExlyImage";
import React from "react";
import { useStyles } from "./listingPage.styles";
import DeleteIcon from "assets/vectors/delete_red.svg";
import CopyIcon from "assets/vectors/copy_new.svg";
import WhatsappIcon from "assets/vectors/whatsapp_nofill.svg";
import PreviewIcon from "assets/vectors/expand_content.svg";
import EditIcon from "assets/vectors/edit.svg";
import VisibilityIcon from "@material-ui/icons/Visibility";
import OpenDrawer from "assets/vectors/open_drawer.svg";
import classnames from "classnames";
import { Chip } from "@my-scoot/component-library-legacy";
import { statusLabelMapping } from "../../whatsappBroadcast.constants";
import { Tooltip } from "@my-scoot/component-library-legacy";
import ExlyDeleteModal from "common/Components/ExlyDeleteModal/ExlyDeleteModal";
import {
  notification_color_keys,
  useNotifications,
  useToggleState,
} from "utils/hooks";
import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { useRefresh } from "react-admin";
import { useHistory } from "react-router";

import { useDispatch } from "react-redux";
import { SET_TEMPLATE_ACTION_TYPE } from "../../redux/actions";
import {
  deleteModalCopy,
  templateActionTypes,
  templateApprovalStatus,
} from "../../create/CustomTemplates/moduleConstants";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import CopyModal from "../CopyModal";
import { MobilePopover } from "@my-scoot/component-library-legacy";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { useWhatsAppHelpers } from "../../useWhatsAppHelpers";
import { whatsapp_broadcasts_apis } from "../../whatsappBroadCast.api";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { setCommDataSession } from "ui/pages/schedule/MangageCommunication/mangageCommunication.helpers";
import { getWhatsappWhiteLabelData } from "../../whatsappBroadcastStorageUtils";

export const TemplatesActionField = withComponentLibraryTheme(
  ({ record = {} }) => {
    const isDesktop = useDesktopMediaQuery();
    const classes = useStyles({ isDesktop });
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [isCopyOpen, openCopyModal, hideCopyModal] = useToggleState(false);
    const { notify } = useNotifications();
    const { uuid, approval_status } = record || {};

    const { hasWhatsappWriteAccess, checkIsAiSensyBlockedAfterEnabling } =
      useWhatsAppHelpers();
    const whatsappWhiteLabelData = getWhatsappWhiteLabelData();

    const isAiSensyBlocked = checkIsAiSensyBlockedAfterEnabling(
      whatsappWhiteLabelData
    );

    const refresh = useRefresh();
    const history = useHistory();

    const dispatch = useDispatch();

    const handleDelete = async () => {
      if (!hasWhatsappWriteAccess) return;
      try {
        const bodyParams = {
          template_uuid: uuid,
        };

        const response = await dataProvider.custom_request(
          whatsapp_broadcasts_apis.delete_custom_template,
          apiMethods.POST,
          { ...bodyParams }
        );

        if (response.status === 200) {
          notify("Template deleted", notification_color_keys.info);
          refresh();
        }
      } catch (err) {
        notify(
          err.message || "Error while deleting the template",
          notification_color_keys.error
        );
      } finally {
        setShowDeleteModal(false);
      }
    };

    const onCopyClicked = () => {
      if (!hasWhatsappWriteAccess || isAiSensyBlocked) return;

      dispatch({
        type: SET_TEMPLATE_ACTION_TYPE,
        payload: templateActionTypes.Copy,
      });

      history.push(`/WhatsappTemplate/${uuid}`);
    };

    const onBroadCastClicked = () => {
      if (!hasWhatsappWriteAccess || isAiSensyBlocked) return;
      // fromTemplatePage indicate that broadcast being created via myTemplates of sales & marketing page
      const commListingData = {
        ...record,
        isCustomTemplate: true,
        fromTemplatesPage: true,
      };
      setCommDataSession(commListingData);
      history.push(
        `${
          app_route_ids[app_route_keys.manage_communications_whatsapp_publish]
        }`
      );
    };

    const onMobilePreviewClicked = () => {
      if (!hasWhatsappWriteAccess) return;

      dispatch({
        type: SET_TEMPLATE_ACTION_TYPE,
        payload: templateActionTypes.Preview,
      });

      history.push(`/WhatsappTemplate/${uuid}`);
    };

    const onPreviewClicked = (e, disabledAction = false) => {
      if (!hasWhatsappWriteAccess || disabledAction) return;

      dispatch({
        type: SET_TEMPLATE_ACTION_TYPE,
        payload: templateActionTypes.ViewTepmaple,
      });

      history.push(`/WhatsappTemplate/${uuid}`);
    };

    return (
      <>
        <div className={classes.info_field_wrapper}>
          {!isDesktop && approval_status !== templateApprovalStatus.Draft && (
            <div
              onClick={onMobilePreviewClicked}
              className={!isDesktop && classes.mobileWrapper}
            >
              <ExlyImage
                src={PreviewIcon}
                alt="PreviewIcon"
                height={20}
                width={20}
              />
              {!isDesktop && <span>Preview message</span>}
            </div>
          )}
          {approval_status === templateApprovalStatus.Approved && (
            <div
              onClick={onBroadCastClicked}
              className={classnames(
                classes.actionStyle,
                !isDesktop && classes.mobileWrapper,
                isAiSensyBlocked && classes.disabledAction
              )}
            >
              <ExlyImage
                src={WhatsappIcon}
                alt="WhatsappIcon"
                height={20}
                width={20}
              />

              {!isDesktop && <span>Use this template</span>}
            </div>
          )}

          {approval_status !== templateApprovalStatus.Draft ? (
            <Tooltip
              title={"View template"}
              placement="top"
              color="primary"
              arrow
            >
              <div
                onClick={onPreviewClicked}
                className={classnames(
                  classes.actionStyle,
                  !isDesktop && classes.mobileWrapper
                )}
              >
                <VisibilityIcon />
                {!isDesktop && <span>View template</span>}
              </div>
            </Tooltip>
          ) : (
            <div
              onClick={(e) => onPreviewClicked(e, isAiSensyBlocked)}
              className={classnames(
                classes.actionStyle,
                !isDesktop && classes.mobileWrapper,
                isAiSensyBlocked && classes.disabledAction
              )}
            >
              <ExlyImage src={EditIcon} alt="EditIcon" height={20} width={20} />
              {!isDesktop && <span>Edit template</span>}
            </div>
          )}
          <div
            onClick={() => {
              approval_status !== templateApprovalStatus.Draft &&
              !isAiSensyBlocked
                ? openCopyModal()
                : onCopyClicked();
            }}
            className={classnames(
              classes.actionStyle,
              !isDesktop && classes.mobileWrapper,
              isAiSensyBlocked && classes.disabledAction
            )}
          >
            <ExlyImage src={CopyIcon} alt="CopyIcon" height={20} width={20} />
            {!isDesktop && <span>Duplicate template</span>}
          </div>
          <div
            onClick={() => {
              setShowDeleteModal(true);
            }}
            className={classnames(
              classes.actionStyle,
              !isDesktop && classes.deleteMobileWrapper
            )}
          >
            <ExlyImage
              src={DeleteIcon}
              alt="DeleteIcon"
              height={20}
              width={20}
            />
            {!isDesktop && <span>Delete template</span>}
          </div>
        </div>
        <ExlyDeleteModal
          open={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          showWarningIcon={false}
          onDelete={handleDelete}
          title="Are you sure?"
          description={deleteModalCopy[approval_status]}
        />
        {isCopyOpen && (
          <CopyModal
            isOpen={isCopyOpen}
            onPrimaryBtnClick={() => onCopyClicked()}
            onClose={hideCopyModal}
            approvalStatus={approval_status}
          />
        )}
      </>
    );
  }
);

export const TemplateStatusChip = ({ status }) => {
  const classes = useStyles();

  return (
    <Chip
      chipClassName={classnames(
        classes.template,
        classes[`template_${status}`]
      )}
      label={statusLabelMapping[status]}
    />
  );
};

/*
Mobile actions (opening in mobile drawer)
*/

export function MobileActionDrawer({ record, label }) {
  const [open, openMobileActions, hideModal] = useToggleState(false);
  const classes = useStyles();

  return (
    <>
      <div onClick={openMobileActions} className={classes.labelWrapper}>
        {label} <ExlyImage src={OpenDrawer} alt={"OpenDrawer"} />{" "}
      </div>
      <MobilePopover open={open} onClose={hideModal}>
        <div className={classes.wrap}>
          <TemplatesActionField record={record} />
        </div>
      </MobilePopover>
    </>
  );
}
