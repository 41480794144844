import ExlyModal from "common/Components/ExlyModal";
import { Button } from "@my-scoot/component-library-legacy";

import React, { memo, useState } from "react";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import IntroImage from "assets/vectors/whatsap-broadcast/intro_image.svg";
import IntroImageMobile from "assets/vectors/whatsap-broadcast/intro_image_mobile.svg";
import useStyles from "./IntroModal.styles";
import ExlyImage from "common/Components/ExlyImage";
import { buttonTexts, introConstantsArray } from "./IntroModal.helper";
import { useHistory } from "react-router";
import { isDeviceIOS } from "common/util";
import {
  WHATSAPP_TEMPLATES_PATHNAMES,
  WHATSAPP_TEMPLATES_ROUTE_KEYS,
} from "features/WhatsappTemplate/WhatsappTemplate.constants";
import { whatsappSubTabsMapping } from "../../list/constants";

function IntroModal({ isOpen, onClose }) {
  const isDesktop = useDesktopMediaQuery();
  const [buttonText, setButtonText] = useState(buttonTexts.Next);
  const isIos = isDeviceIOS();

  const classes = useStyles({ isDesktop, isIos });
  const isFirstStep = buttonText === buttonTexts.Next;

  const history = useHistory();

  const onButtonClickHandler = () => {
    if (isFirstStep) {
      setButtonText(buttonTexts.Continue);
    } else {
      onClose();
      // navigating back Journey on navigating back from whatsapp template screen

      // history.replace is used because we wish the following
      // - sales and marketing page

      // history.push is not used because we do not wish the following journey
      // - whatsapp broadcast
      // - sales and marketing page

      history.replace(
        WHATSAPP_TEMPLATES_PATHNAMES[
          WHATSAPP_TEMPLATES_ROUTE_KEYS.template_list
        ](whatsappSubTabsMapping.Approved)
      );
    }
  };

  return (
    <ExlyModal
      open={isOpen}
      onClose={onClose}
      modal_props={{
        customHeader: <></>,
        customFooter: <></>,
        backDropClickClose: true,
      }}
      className={classes.desktopModalPaper}
      mobile_modal_props={{
        customFooter: <></>,
        customHeader: <></>,
        paperClassName: classes.mobile_modal_paper,
      }}
      modalPaperClassName={classes.desktopModalPaper}
      paperClassName={classes.mobile_modal_paper}
      backDropClickClose
    >
      {isFirstStep ? (
        <>
          <div>
            <ExlyImage
              src={isDesktop ? IntroImage : IntroImageMobile}
              alt={"IntroImage"}
              width={isDesktop ? 600 : "100%"}
            />
          </div>
          <div className={classes.contentWrapper}>
            <div className={classes.blackBold}>Create your own</div>
            <div className={classes.boldPurple}>WhatsApp messages</div>
            <p className={classes.para}>
              WhatsApp is a business marketing channel with huge potential.
              However, there are some features making it unique. That includes
              WhatsApp custom templates. You can now create messages and
              broadcast them to your audience!
            </p>
          </div>
        </>
      ) : (
        <div className={classes.contentWrapperAlt}>
          <div className={classes.blackBold}>Advantages of</div>
          <div className={classes.boldPurple}>Custom Template</div>
          <div className={classes.introTileWrapper}>
            {introConstantsArray.map((introVal) => (
              <div key={introVal.title} className={classes.introTile}>
                <ExlyImage src={introVal.img} alt={introVal.title} />
                <div>
                  <span className={classes.introTitle}>{introVal.title}</span>
                  <p className={classes.introPara}>{introVal.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className={classes.buttonDiv}>
        <Button
          onClick={() => {
            onButtonClickHandler();
          }}
          size="medium"
          fullWidth
        >
          {buttonText}
        </Button>
      </div>
    </ExlyModal>
  );
}

export default memo(IntroModal);
