import { feature_activation_keys } from "features/FeatureActivationStatus/FeatureActivationStatus.constants";
import React from "react";
import { useWhatsAppHelpers } from "ui/pages/whatsappBroadcast/useWhatsAppHelpers";
import useFeaturesActivationStatus from "features/FeatureActivationStatus/utils/useFeaturesActivationStatus";
import styles from "../styles.module.css";
import classnames from "classnames";

const RenderPhoneNumber = (props) => {
  const { data, customStyle } = props;
  const { loading, featuresActivationStatus } = useFeaturesActivationStatus();
  const { getDisplayPhoneNumber } = useWhatsAppHelpers();
  const { is_whitelabelled_whatsapp_allowed } = feature_activation_keys;
  const phoneNumber = getDisplayPhoneNumber(data);
  return (
    <>
      {!loading &&
        featuresActivationStatus[is_whitelabelled_whatsapp_allowed.key] && (
          <div className={classnames(styles.dateTime, customStyle)}>
            Sent from &nbsp;{phoneNumber}
          </div>
        )}
    </>
  );
};

export default RenderPhoneNumber;
