import ExlyModal from "common/Components/ExlyModal";

import React, { memo } from "react";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import useStyles from "./copyModal.styles";
import { statusLabelMapping } from "../../whatsappBroadcast.constants";
import { templateApprovalStatus } from "../../create/CustomTemplates/moduleConstants";

function CopyModal({ isOpen, onClose, onPrimaryBtnClick, approvalStatus }) {
  const isDesktop = useDesktopMediaQuery();

  const classes = useStyles({ isDesktop });

  return (
    <ExlyModal
      title={"Note"}
      open={isOpen}
      onClose={onClose}
      primaryBtnText={"I understand"}
      onPrimaryBtnClick={onPrimaryBtnClick}
      onSecondaryBtnClick={onClose}
      secondaryBtnText={"Cancel"}
      modal_props={{
        backDropClickClose: true,
      }}
      className={classes.desktopModalPaper}
      mobile_modal_props={{
        paperClassName: classes.mobile_modal_paper,
      }}
      modalPaperClassName={classes.desktopModalPaper}
      paperClassName={classes.mobile_modal_paper}
      backDropClickClose
    >
      <div>
        Please know, duplicating{" "}
        {approvalStatus === templateApprovalStatus.Approved ? "an" : "a"}{" "}
        <strong>{statusLabelMapping[approvalStatus]}</strong> template will only
        duplicate the
        {approvalStatus === templateApprovalStatus.Approved
          ? " approved "
          : approvalStatus === templateApprovalStatus.Rejected
          ? " current "
          : " submitted "}
        version of the template, and save it as a draft. Once you edit the
        duplicated template, you’ll need to resubmit the new version for
        approval.
      </div>
    </ExlyModal>
  );
}

export default memo(CopyModal);
