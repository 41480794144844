import React from "react";
import classes from "./customTemplates.module.css";
import { WHATSAPP_TEMPLATE_LANGUAGE_DEFAULT } from "features/WhatsappTemplate/modules/LanguageSelectionDropdown/LanguageSelectionDropdown.constants";
import { BUTTON_TYPES } from "features/WhatsappTemplate/WhatsappTemplate.constants";

export const TemplateCategory = [
  {
    id: 2,
    value: 2,
    label: "Marketing",
  },
  {
    id: 5,
    value: 5,
    label: "Utility",
  },
];

export const WhatsappTemplateNameMapping = {
  1: "Transactional",
  2: "Marketing",
  3: "OTP",
  4: "Authentication",
  5: "Utility",
};
export const buttonTypes = {
  visitWebsite: {
    value: "URL",
    label: "Visit website",
  },
  phoneNo: {
    value: "PHONE_NUMBER",
    label: "Call Phone no.",
  },
};

export const headerTypeOptions = [
  {
    value: 1,
    label: "None",
  },
  {
    value: 5,
    label: "Text",
  },
  {
    value: 2,
    label: "Img",
  },
  {
    value: 3,
    label: "Video",
  },
  {
    value: 4,
    label: "Doc",
  },
  // {
  //   value: 6,
  //   label: "Media",
  // },
];

export const headerTypeRadioOptions = [
  {
    value: 2,
    label: "Img",
  },
  {
    value: 3,
    label: "Video",
  },
  {
    value: 4,
    label: "Doc",
  },
];

export const email_variable_values = {
  email: "{{email}}",
  country: "{{country}}",
  offering_name: "{{offering_name}}",
  offering_link: "{{offering_link}}",
  offering_location: "{{offering_location}}",
};

export const whatsapp_variables = [
  { id: "1", label: "full_name", value: "{{full_name}}" },
  {
    id: "2",
    label: "email",
    value: email_variable_values.email,
    // secondaryColor: true,
  },
  {
    id: "3",
    label: "country",
    value: email_variable_values.country,
    // secondaryColor: true,
  },
  {
    id: "4",
    label: "offering_name",
    value: email_variable_values.offering_name,
    secondaryColor: true,
  },
  {
    id: "5",
    label: "offering_payment_link",
    value: email_variable_values.offering_link,
    secondaryColor: true,
  },
  {
    id: "6",
    label: "offering_location",
    value: email_variable_values.offering_location,
    secondaryColor: true,
  },
];

export const initialValues = {
  display_title: "",
  template_type: TemplateCategory[0].value,
  header_data: {
    type: headerTypeOptions[0].value,
    text: "",
    headerType: "",
  },
  footer: "",
  cta_radio: BUTTON_TYPES.NONE.value,
  cta_data: [],
  locale: WHATSAPP_TEMPLATE_LANGUAGE_DEFAULT.value,
};

export const templateActionTypes = {
  Copy: "Copy",
  Preview: "Preview",
  DynamicRoute: "DynamicRoute",
  ViewTepmaple: "ViewTepmaple",
};

export const templateApprovalStatus = {
  Draft: 1,
  UnderReview: 2,
  Submitted: 3,
  Approved: 4,
  Rejected: 5,
};

export const buttonCtaMapping = {
  sendForApproval: "Send for approval",
  useTemplate: "Use this template",
  hideButton: "",
};

export const exly_creator_name = "Exly";

export const templateToolTipTitle = (
  <div className={classes.tooltip}>
    Your WhatsApp message template must fall into one of the following
    categories (in the dropdown). Templates that do not align clearly with these
    categories are more likely to be rejected by WhatsApp in the template
    approval process.
    <br></br>
    If you are not sure which category to choose, go ahead with the{" "}
    <span className={classes.bold}>‘Marketing’</span> category.
  </div>
);

export const footerToolTipTitle = (
  <div className={classes.tooltip}>
    The <span className={classes.bold}>Footer</span> will appear at the bottom
    of your message. You may use this to smartly place your business name, or
    you can also place any statement that you’d like to differentiate from the
    rest of the message.
  </div>
);

export const deleteModalCopy = {
  [templateApprovalStatus.Approved]: (
    <div>
      Please know, you are deleting an{" "}
      <span className={classes.bold}>approved</span> template, hence you will no
      longer be able to use it for broadcasts. This action will be
      unretrievable.
    </div>
  ),
  [templateApprovalStatus.Draft]: (
    <div>Please know, this action will be unreterivable.</div>
  ),
  [templateApprovalStatus.UnderReview]: (
    <div>
      Please know, you are deleting a template that is currently with Meta for
      approval. This action will be unretrievable.{" "}
    </div>
  ),
  [templateApprovalStatus.Submitted]: (
    <div>
      Please know, you are deleting a template that is currently with Meta for
      approval. This action will be unretrievable.{" "}
    </div>
  ),
  [templateApprovalStatus.Rejected]: (
    <div>
      <span className={classes.purpleText}>
        You can still make improvements to this template and retry submitting
        for approval!{" "}
      </span>
      <br></br>
      <br></br>
      <span className={classes.bold}> Suggestion:</span> If you are planning to
      resubmit the template for approval, you can simply duplicate this template
      and replenish the duplicated template, instead of/before deleting this
      version.
    </div>
  ),
};

export const bodyCharactersLimit = 1024;

export const ctaInputLimit = 20;
export const minimumCta = 1;
export const initialUniqueId = 0;
export const onboardDelay = 100;
export const headerValidations = {
  min: "Header must be atleast 3 characters",
  max: "Header must be atmost 60 characters",
  minVal: 3,
  maxVal: 60,
};

export const footerValidationText = "Footer must be atmost 60 characters";
export const footerLength = 60;

export const urlValidationString = {
  min: "url must be atleast 6 characters",
  max: "url must be atmost 2000 characters",
  minVal: 6,
  maxVal: 2000,
};

export const ctaTextValidationString = {
  min: "Text must be atleast 3 characters",
  max: "Text must be atmost 20 characters",
  minVal: 3,
  maxVal: 20,
};
