import { format } from "date-fns";
import {
  date_fns_formats,
  parseDateStringForGivenTz,
} from "utils/dateTimeUtils";
import React from "react";
import {
  TemplatesActionField,
  TemplateStatusChip,
} from "./ListingTableComponents";
import { templateCategoryMapping } from "../../whatsappBroadcast.constants";
import {
  WHATSAPP_TEMPLATE_LANGUAGES_OPTIONS,
  WHATSAPP_TEMPLATE_LANGUAGE_DEFAULT,
} from "features/WhatsappTemplate/modules/LanguageSelectionDropdown/LanguageSelectionDropdown.constants";

export const table_col_keys = {
  display_title: "display_title",
  template_type: "template_type",
  session_date: "session_date",
  first_join_time: "first_join_time",
  last_leave_time: "last_leave_time",
};

export const formatTime = (val) =>
  format(parseDateStringForGivenTz(val), date_fns_formats.hour_min__AMPM);

export const getTableConfigTemplates = () => [
  {
    field: table_col_keys.display_title,
    headerName: "Template name",
  },
  {
    field: table_col_keys.template_type,
    headerName: "Category",
    valueFormatter: (record) =>
      `${templateCategoryMapping[record?.template_type]}`,
  },
  {
    field: table_col_keys.session_date,
    headerName: "Language",
    valueFormatter: (record) =>
      WHATSAPP_TEMPLATE_LANGUAGES_OPTIONS?.find(
        (item) => item.value === record.locale
      )?.label || WHATSAPP_TEMPLATE_LANGUAGE_DEFAULT.label,
  },
  {
    field: table_col_keys.first_join_time,
    headerName: "Status",
    valueFormatter: (record) => {
      return <TemplateStatusChip status={record?.approval_status} />;
    },
    hidden: true,
  },
  {
    field: "",
    headerName: "Actions",
    valueFormatter: (record) => <TemplatesActionField record={record} />,
    isCollapsed: true,
    hidden: true,
    noWrap: true,
    fixed: "right",
    width: "200px",
  },
];
