import Personalization from "assets/vectors/whatsap-broadcast/personalization.svg";
import Resusability from "assets/vectors/whatsap-broadcast/reusability.svg";

export const buttonTexts = {
  Next: "Next",
  Continue: "Continue",
};

export const introConstantsArray = [
  {
    img: Personalization,
    title: "Personalization",
    content:
      "Most message templates allow you to add a personalised touch which makes the receiver feel special.",
  },
  {
    img: Resusability,
    title: "Reusability",
    content:
      "Whether you want to send order updates or a reminder, you can reuse the templates as and when you like.",
  },
];
