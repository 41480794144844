import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "utils/getterAndSetters";
import { whatsapp_white_label_data } from "./whatsappBroadcast.constants";

export const getWhatsappWhiteLabelData = () => {
  const whiteLabelSessionData = getSessionStorageItem(
    whatsapp_white_label_data
  );
  return whiteLabelSessionData || {};
};

export const setWhatsappWhiteLabelData = (data) => {
  setSessionStorageItem(whatsapp_white_label_data, data);
};
