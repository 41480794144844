import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: ({ isDesktop }) => (isDesktop ? 0 : 20),
    "& *": {
      fontFamily: "unset",
    },
  },
  header: {
    display: "flex",
    flexDirection: ({ isDesktop }) => (isDesktop ? "row" : "column"),
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
  },
  subHeader: {
    padding: "20px 0",
    paddingTop: ({ isDesktop }) => (isDesktop ? "20px" : "0px"),
  },
  whatsappMobileHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    color: "#272522",
    marginRight: "auto",
  },
  createButtonContainer: ({ isDesktop }) =>
    isDesktop
      ? {
          display: "flex",
          gap: "16px",
        }
      : {
          position: "fixed",
          bottom: "0px",
          width: "100%",
          // display: "flex",
          padding: "10px",
          zIndex: 100,
          backgroundColor: "#fff",
        },
  createButton: ({ isDesktop }) => ({
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    ...(isDesktop
      ? {
          maxWidth: 250,
        }
      : {
          width: "100%",
        }),
  }),
}));
