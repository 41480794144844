import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: "16px 0px 16px 12px",
    border: "1.5px solid #E7E6E4",
    borderRadius: "8px",
    "& *": {
      fontFamily: "unset",
    },
  },
  mailTo: {
    color: "#272522",
    fontSize: "16px",
    fontWeight: 500,
    textDecoration: "underline",
    cursor: "pointer",
    maxWidth: "48vw",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
      color: theme?.palette?.primary?.main,
    },
  },
  subject: {
    color: "#272522",
    fontSize: "16px",
    marginBottom: "10px",
  },
  dataContainer: {
    display: "flex",
    gap: "24px",
  },
  sentTo: {
    color: "#493AB1",
    fontSize: "0.9rem",
  },
  deliveredTo: {
    color: "#EDB183",
    fontSize: "0.9rem",
  },
  openedBy: {
    color: "#00B77A",
    fontSize: "0.9rem",
  },
  openedRate: {
    color: "#0052CC",
    fontSize: "0.9rem",
  },
  dark: {
    background: "#F3F2F1",
  },
  mobileRoot: {
    display: "block",
  },
  mobileHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1.5px solid #E7E6E4",
    paddingBottom: "10px",
    marginBottom: "10.5px",
  },
  headerLeftContainer: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
  headerRightContainer: {
    display: "flex",
    gap: "14px",
    alignItems: "center",
  },
  moreIcon: {
    padding: 0,
    marginRight: "16px",
    color: theme?.palette?.primary?.main,
  },
  mobileFooter: {
    background: "#EEECF9",
    borderRadius: "0px 0px 5px 5px",
    margin: "0px -1.5px -17.5px -13.5px",
    padding: "12px",
    marginTop: "12px",
  },
  paperClassName: {
    padding: "16px",
  },
  option: {
    fontSize: "16px",
    marginBottom: "14px",
  },
  campaignDataContainer: {
    display: "flex",
    gap: "6px",
    marginRight: "16px",
    alignItems: "center",
    marginTop: "30px",
  },
  scheduled: {
    color: "#F4B64E",
    fontSize: "14px",
  },
  chip: {
    height: "23px",
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    padding: "0px 6px",
    borderRadius: "11.5px",
    textDecoration: "none",
  },
  campaignStep: {
    borderRadius: "14px",
    fontSize: "12px",
    padding: "6px",
    textAlign: "center",
    color: theme?.palette?.basic?.white,
  },
  draftChip: {
    background: "#F7D6D4",
    color: "#AA2822",
  },
  scheduledChip: {
    background: "#FFEECC",
    color: "#FFAA00",
  },
  campaignAdded: {
    background: theme?.palette?.primary?.shade_500,
  },
  campaignProcessing: {
    background: theme?.palette?.butterscotch_yellow?.shade_500,
  },
  campaignFailed: {
    background: theme?.palette?.basic?.danger_red,
  },
  campaignCompleted: {
    background: theme?.palette?.fine_pine?.shade_500,
  },
}));
