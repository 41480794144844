import React, { useEffect } from "react";

import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { Tabs } from "@my-scoot/component-library-legacy";

import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";

import ExlyTabsWrapper from "common/Components/ExlyTabsWrapper";
import { whatsappTabsMapping, whatsappTabsValue } from "./constants";
import { useWhatsAppHelpers } from "../useWhatsAppHelpers";
import WhatsappListLayout from "./WhatsappListLayout";
import { useToggleState } from "utils/hooks";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";
import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";
import { onboardDelay } from "../create/CustomTemplates/moduleConstants";
import IntroModal from "../Components/IntroModal";
import BroadcastList from "./BroadCastList";

const ListWhatsappBroadcast = (props) => {
  const isDesktop = useDesktopMediaQuery();
  const tabValue = whatsappTabsMapping.broadcast;
  const { handleTabsChange } = useWhatsAppHelpers();

  const hasWriteAccess = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.WHATSAPP_BROADCASTS
  );

  const [isIntroModalActive, showIntroModal, hideIntroModal] =
    useToggleState(false);

  const { isOnboarded, flagLoading, handleFeatureOnboardChange } =
    useFeatureOnboardingChecker({
      featureKey: onboardingFeatureKeys.WHATSAPP_CUSTOM_TEMPLATES,
    });

  const closeGuideModal = () => {
    hideIntroModal();
    handleFeatureOnboardChange(true);
  };

  useEffect(() => {
    if (flagLoading) return;

    if (!isOnboarded) {
      setTimeout(() => {
        showIntroModal();
      }, onboardDelay);
    }
  }, [isOnboarded, flagLoading]);

  return (
    <WhatsappListLayout tabValue={tabValue} props={props}>
      <ExlyTabsWrapper>
        <Tabs
          key="level-one"
          value={tabValue}
          onChange={handleTabsChange}
          tabs={whatsappTabsValue}
        />
      </ExlyTabsWrapper>
      {tabValue === whatsappTabsMapping.broadcast && (
        <BroadcastList isDesktop={isDesktop} hasWriteAccess={hasWriteAccess} />
      )}
      {isIntroModalActive && (
        <IntroModal isOpen={isIntroModalActive} onClose={closeGuideModal} />
      )}
    </WhatsappListLayout>
  );
};

export default withComponentLibraryTheme(ListWhatsappBroadcast);
