import DraftsEmpty from "assets/vectors/whatsap-broadcast/drafts_empty.svg";
import DraftsEmptyMobile from "assets/vectors/whatsap-broadcast/drafts_empty_mobile.svg";
import InitialEmpty from "assets/vectors/whatsap-broadcast/initial_empty.svg";
import PendingEmpty from "assets/vectors/whatsap-broadcast/pending_empty.svg";
import PendingEmptyMobile from "assets/vectors/whatsap-broadcast/pending_empty_mobile.svg";
import RejectedEmpty from "assets/vectors/whatsap-broadcast/rejected_empty.svg";
import RejectedEmptyMobile from "assets/vectors/whatsap-broadcast/rejected_empty_mobile.svg";
import { whatsappSubTabsMapping } from "../../list/constants";
import { WHATSAPP_BROADCASTS_LEARN_MORE_HREFS } from "../../whatsappBroadcast.constants";

export const emptyScreensMapping = {
  [whatsappSubTabsMapping.Initial]: {
    image: InitialEmpty,
    heading: "No custom templates yet.",
    content:
      "Custom message templates allow you to create your own personalised message templates that you can use for your WhatsApp broadcasts! Create your first custom message and send it out!",
    btnText: "Create custom template",
    cta: "",
  },
  [whatsappSubTabsMapping.Approved]: {
    heading: "No templates yet.",
    content: "All your Meta approved templates will appear here.",
    videoSrc: WHATSAPP_BROADCASTS_LEARN_MORE_HREFS.WHATSAPP_BROADCASTS_VIDEO,
  },
  [whatsappSubTabsMapping.Pending]: {
    image: PendingEmpty,
    imageMobile: PendingEmptyMobile,
    heading: "No templates yet.",
    content:
      "All the templates that you submit to Meta for approval will appear here.",
  },
  [whatsappSubTabsMapping.Rejected]: {
    image: RejectedEmpty,
    imageMobile: RejectedEmptyMobile,
    heading: "No templates yet.",
    content: "All the templates that were unapproved by Meta will appear here.",
  },
  [whatsappSubTabsMapping.Drafts]: {
    image: DraftsEmpty,
    imageMobile: DraftsEmptyMobile,
    heading: "No templates yet.",
    content: "All your draft templates will appear here.",
  },
};
