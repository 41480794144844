import React from "react";

function StringToHtml({ stringToConvert, style }) {
  const myElement = React.createElement("div", {
    dangerouslySetInnerHTML: {
      __html: `<p style="${style}">${
        stringToConvert === "undefined" ? "" : stringToConvert
      }</p>`,
    },
  });

  return myElement;
}

export default StringToHtml;
