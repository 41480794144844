import React from "react";

import DataItem from "../DataItem";

import { useStyles } from "./MobileRow.styles";

import WhatsappIcon from "assets/vectors/whatsap-broadcast/whatsapp_white.svg";
import { Tooltip } from "@my-scoot/component-library-legacy";
import InfoIcon from "@material-ui/icons/InfoOutlined";

import { IconButton } from "@material-ui/core";

import { MoreHorizOutlined as MoreIcon } from "@material-ui/icons";

import classnames from "classnames";
import {
  getStatusChip,
  whatsappCampaignStatusChoices,
} from "../../../constants";
import {
  campaignStepsData,
  WHATSAPP_CAMPAIGN_DESCRIPTIONS,
} from "ui/pages/whatsappBroadcast/whatsappBroadcast.constants";
import RenderPhoneNumber from "../RenderPhoneNumber";

const MobileRow = ({
  data,
  time,
  alternate,
  recipients,
  onMoreClick,
  handlePreview,
  hasWriteAccess,
  type,
  handleEdit,
  getTitle,
}) => {
  const classes = useStyles();

  const isDraft = type === whatsappCampaignStatusChoices.Drafts;
  const isScheduled = type === whatsappCampaignStatusChoices.Scheduled;

  return (
    <div
      className={classnames(
        classes.root,
        classes.mobileRoot,
        alternate && classes.dark,
        !hasWriteAccess && "disable_pointer"
      )}
    >
      <div className={classes.mobileHeader}>
        <div className={classes.headerLeftContainer}>
          <img src={WhatsappIcon} alt="whatsapp main logo" />
          <span
            className={classes.mailTo}
            onClick={isDraft ? handleEdit : handlePreview}
          >
            To: {data?.audience?.segment?.title || recipients}
          </span>
        </div>

        <div className={classes.headerRightContainer}>
          {getStatusChip({ type, classes })}

          <IconButton className={classes.moreIcon} onClick={onMoreClick}>
            <MoreIcon />
          </IconButton>
        </div>
      </div>

      <div className={classes.subject}>Campaign Name: {data.title}</div>
      {getTitle({ time, isDraft, isScheduled, type })}
      {!isDraft && !isScheduled && <RenderPhoneNumber data={data} />}
      {[
        whatsappCampaignStatusChoices.Drafts,
        whatsappCampaignStatusChoices.Scheduled,
      ].indexOf(type) < 0 ? (
        <div
          className={classnames(classes.dataContainer, classes.mobileFooter)}
        >
          {[
            [
              "Sent to",
              data?.sent_count,
              classes.sentTo,
              "Number of users to whom the campaign was initiated",
            ],
            [
              "Delivered to",
              data?.delivered_count,
              classes.deliveredTo,
              "Number of users to whom the message was delivered",
            ],
            [
              "Opened by",
              data?.read_count,
              classes.openedBy,
              "Number of users who read the message (Read receipts of the user need to be enabled)",
            ],
            [
              "Opened rate(%)",
              data?.sent_count
                ? `${parseFloat(
                    (data?.read_count / data?.sent_count) * 100
                  ).toFixed(2)}%`
                : "N/A",
              classes.openedRate,
            ],
          ].map(([title, value, className, tooltip], i) => (
            <DataItem
              dataClassName={className}
              title={title}
              data={value}
              tooltip={tooltip}
              // eslint-disable-next-line react/no-array-index-key
              key={i}
            />
          ))}
        </div>
      ) : (
        <></>
      )}
      {type === whatsappCampaignStatusChoices.Completed ? (
        <div className={classes.campaignDataContainer}>
          <div
            className={classnames(
              classes.campaignStep,
              classes[campaignStepsData[`${data?.status}`]?.className]
            )}
          >
            {campaignStepsData[`${data?.status}`]?.label}
          </div>
          <Tooltip
            arrow
            color="primary"
            placement="top"
            title={WHATSAPP_CAMPAIGN_DESCRIPTIONS[`${data?.status}`]}
          >
            <InfoIcon />
          </Tooltip>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MobileRow;
