import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { List, Pagination, SimpleList, useRefresh } from "react-admin";
import { Tabs } from "@my-scoot/component-library-legacy";

import ListItem from "./item";
import {
  EmptyState,
  whatsappBroadCaseSubTabsValue,
  whatsappCampaignStatusChoices,
} from "./constants";
import qs from "query-string";
import { getParameterByName } from "utils/Utils";
import { STATUS } from "../whatsappBroadcast.constants";
import { whatsapp_broadcasts_apis } from "../whatsappBroadCast.api";
import { resources } from "data";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";
import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";

//Not revamped only refactored
//Constant intentionally kept here else throws error
export const whatsappListProps = {
  basePath: `/whatsapp-broadcasts`,
  perPage: 50,

  history: {
    length: 6,
    action: "POP",
    location: {
      pathname: `/${whatsapp_broadcasts_apis.list_campaigns}`,
      search: "",
      hash: "",
    },
  },
  location: {
    pathname: `/${whatsapp_broadcasts_apis.list_campaigns}`,
    search: "",
    hash: "",
  },
  match: {
    path: `/${whatsapp_broadcasts_apis.list_campaigns}`,
    url: `/${whatsapp_broadcasts_apis.list_campaigns}`,
    isExact: true,
    params: {},
  },
  options: { label: `${whatsapp_broadcasts_apis.list_campaigns}` },

  resource: resources.whatsapp_campaigns,

  hasList: true,
  hasEdit: false,
  hasShow: false,
  hasCreate: false,
  syncWithLocation: false,
};

const BroadcastList = ({ isDesktop, hasWriteAccess }) => {
  const history = useHistory();
  const [subTabValue, setSubTabValue] = React.useState(
    Number(getParameterByName(STATUS)) ||
      whatsappBroadCaseSubTabsValue[whatsappCampaignStatusChoices.Completed]
        .value
  );
  const refresh = useRefresh();
  const {
    isOnboardedPrevVal: isFeatureVisited,
    flagLoading: isFeatureFlagLoading,
    handleFeatureOnboardChange,
  } = useFeatureOnboardingChecker({
    featureKey: onboardingFeatureKeys.KNOWLEDGE_BASE.WHATSAPP_BROADCAST,
    successCallback: (marked) => !marked && handleFeatureOnboardChange(true),
  });

  const handleChange = useCallback((event, newValue) => {
    setSubTabValue(newValue);
    const query = { Status: newValue };
    const searchString = qs.stringify(query);
    history.push({ search: searchString });
    refresh();
  }, []);

  const handleCreate = () => {
    history.push(
      `${app_route_ids[app_route_keys.manage_communications_whatsapp_select]}`
    );
  };

  const ListPagination = () => (
    <Pagination rowsPerPageOptions={[]} {...whatsappListProps} />
  );

  return (
    <>
      <Tabs
        key="level-two"
        value={subTabValue}
        onChange={handleChange}
        tabVariant="filled_primary"
        tabs={Object.values(whatsappBroadCaseSubTabsValue).sort((a, b) => {
          if (a.Position > b.Position) return 1;
          else return -1;
        })}
      />
      <List
        id={"list_whatsapp_campaigns"}
        {...whatsappListProps}
        component={"div"}
        exporter={false}
        pagination={<ListPagination />}
        actions={null}
        empty={
          <EmptyState
            loading={isFeatureFlagLoading}
            handleCreate={handleCreate}
            hasWriteAccess={hasWriteAccess}
            subTabValue={subTabValue}
            autoplay={!isFeatureFlagLoading && !isFeatureVisited}
          />
        }
      >
        <div>
          <SimpleList
            rowStyle={() => ({
              paddingLeft: 0,
              paddingRight: 0,
            })}
            className={`dm_font full_width`}
            linkType={false}
            listItemProps
            primaryText={(item, id) => (
              <ListItem
                id={id}
                data={item}
                key={item.uuid}
                isDesktop={isDesktop}
                hasWriteAccess={hasWriteAccess}
                subTabValue={subTabValue}
              />
            )}
          />
        </div>
      </List>
    </>
  );
};

export default React.memo(BroadcastList);
